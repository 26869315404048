import React from "react";
import { Grid } from '@material-ui/core';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Link } from 'gatsby';

export default function PressReleaseLanding({ pressData, basePath, colsCount }) {

  return (
    <>
      {pressData.slice(0, colsCount).map((data, index) => (
        <>
          {
            (index % 4 === 0 || index % 4 === 1) ?

              <Grid container className="press-item">
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Link to={`/${basePath}/` + data.slug}>
                  <div className="press-release-content-div">
                    <img src={data?.pressReleaseConent?.heroImage?.file?.url+'?fm=webp&q=100'}
                      alt='pressImage' locId="heroImage"
                      className='pressImage' />
                    </div>
                  </Link>
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Link to={`/${basePath}/` + data.slug}>
                  <div className="press-release-content-div">
                      <p className="title" locId="pressReleaseTitle">
                        {data?.pressReleaseConent?.pressContentTitle
                          ? data?.pressReleaseConent?.pressContentTitle
                          : data?.pressReleaseConent?.pressReleaseTitle}
                      </p>
                      <p className="date" locId="dateOfPublication">
                        {data?.publishingDate}
                      </p>
                      <p className="des" locId="description">
                        {data?.pressReleaseConent?.description &&
                          documentToReactComponents(JSON.parse(data?.pressReleaseConent?.description?.raw))}
                      </p>
                    </div>
                  </Link>
                </Grid>
              </Grid>
              :
              <Grid container className="press-item row-reverse-mobile" >
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Link to={`/${basePath}/` + data.slug}>
                  <div className="press-release-content-div">
                      <p className="title" locId="pressReleaseTitle">
                        {data?.pressReleaseConent?.pressContentTitle
                          ? data?.pressReleaseConent?.pressContentTitle
                          : data?.pressReleaseConent?.pressReleaseTitle}
                      </p>
                      <p className="date" locId="dateOfPublication">
                        {data?.publishingDate}
                      </p>
                      <p className="des" locId="description">
                        {data?.pressReleaseConent?.description &&
                          documentToReactComponents(JSON.parse(data?.pressReleaseConent?.description?.raw))}
                      </p>
                    </div>
                  </Link>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Link to={`/${basePath}/` + data.slug}>
                  <div className="press-release-content-div">
                    <img src={data?.pressReleaseConent?.heroImage?.file?.url+'?fm=webp&q=100'}
                      alt='pressImage' locId="heroImage"
                      className='pressImage' />
                    </div>
                  </Link>
                </Grid>
              </Grid>
          }
        </>
      ))
      }
    </>
  )
}