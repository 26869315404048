import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import PressReleaseLanding from '../components/PressReleaseLanding';
import Layout from '../components/layout';
import SEO from '../components/seo-component/seo';

export default function PressRoom({ location,pageContext, data }) {
  const [user, setUser] = useState({});
  const allFooterData = [pageContext.appData.footerMenu, pageContext.appData.siteFooter];
  const logoutUser = () => {
    clearSessionCookie();
    localStorage.setItem('user', JSON.stringify(false));
    window.location.reload();
  };

  const clearSessionCookie = () => {
    // the cookie to set is 'SESSION_USER'
    let cookieString = 'SESSION_USER=';
    const pathName = window.location.pathname.split('/')[1].trim();
    // we need to expire this cookie, so lets create a date object that points to yesterday
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() - 86400 * 1000);
    // change the cookie parameters and write the cookie back
    cookieString += `; max-age=0; path=/${pathName}`;
    cookieString += `; expires=${expiryDate.toUTCString()}`;
    document.cookie = cookieString;
  };


  return (
    <>
      <Layout
        partnerCode={pageContext.partnerCode}
        navigation={pageContext.appData}
        footer={allFooterData}
        styles={pageContext.theme}
        contactFormDetails={pageContext.appData.contactUsFormDetails}
        siteType={pageContext.appData.siteType}
        logout={logoutUser}
        metadata={pageContext.theme.partnerMetaData}
        searchEnabled={pageContext.appData.enableSearch}
        astEnabled={pageContext.appData.hasAgentSelectionToolAstRequired}
        basePath={pageContext.basePath}
        bucketName={pageContext?.bucketName}
        location={location}
      >
        <SEO
          data={data?.contentfulPressReleaseLandingPage?.seoEntry}
          siteurl={pageContext?.slug}
          bucketName={pageContext?.appData?.adminDetails?.s3BucketName}
          basePath={pageContext?.basePath}
          organizationName={pageContext?.appData?.organizationName}
          organizationLogo={pageContext?.appData?.organizationLogo?.file?.url}
          organizationLinkedinLink={pageContext?.appData?.organizationLinkedinLink}
          organizationFacebookLink={pageContext?.appData?.organizationFacebookLink}
          organizationYoutubeLink={pageContext?.appData?.organizationYoutubeLink}
          location={location}
        />

        <PressReleaseLanding
          landingData={data?.contentfulPressReleaseLandingPage}
          pressData={data?.allContentfulPressRoom?.nodes}
          basePath={pageContext.basePath}
        />
      </Layout>
    </>
  );
}
export const query = graphql`
  query PressReleaseLandingPageQuery($contentful_id: String, $locale: String) {
    contentfulPressReleaseLandingPage(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $locale }
    ) {
      contentful_id
      loadMoreButton
      node_locale
      header
      entryTitle
      capsulesBackgroundImage {
        description
        title
        file {
          url
        }
      }
      dotMatrixBackgroundImage {
        description
        title
        file {
          url
        }
      }
      rightDonutBackgroundImage {
        description
        title
        file {
          url
        }
      }
      seoEntry {
        pageTitle
        metaDescription {
          metaDescription
        }
        metaKeywords
        metaRobotsTag
        ogSiteName
        ogType
        ogImage {
          file {
            url
          }
        }
        defaultOgLocale
        altimageTag
      }
      letsTalkSectionTitle {
        raw
      }
      letsTalkSectionBackgroundImage {
        file {
          url
        }
      }
      letsTalkButtonWithLink {
        label
        link {
          ... on ContentfulInternalLink {
            slug
          }
          ... on ContentfulWebLink {
            url
          }
        }
      }
    }
    allContentfulPressRoom(filter: { node_locale: { eq: $locale } }) {
      nodes {
        id
        entryTitle
        contentful_id
        node_locale
        pressReleaseType
        slug
        pressReleaseConent {
          ... on ContentfulPressReleaseLongContent {
            id
            categoryType
            contentful_id
            dateOfPublication(locale: "US")
            description {
              raw
            }
            heroImage {
              file {
                url
              }
              description
            }
            labelForPrimaryCta
            title
            node_locale
            pressContentTitle
          }
          ... on ContentfulPressReleaseShortContent {
            id
            contentful_id
            categoryType
            dateOfPublication(locale: "US")
            pressReleaseTitle
            description {
              raw
            }
            heroImage {
              description
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`;
