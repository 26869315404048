import { makeStyles } from '@material-ui/core';

export const myStyles = makeStyles(() => ({
  "bg-donuts-right": (data) => ({
    background: `url(${data?.rightDonutBackgroundImage?.file.url}?fm=webp&q=100) no-repeat`,
  }),

  "bg-capsules-right": (data) => ({
    background: `url(${data?.capsulesBackgroundImage?.file.url}?fm=webp&q=100) no-repeat`,
  }),
  'bg-dot-matrix': (data) => ({
    '&::after': {
      background: `url(${data?.dotMatrixBackgroundImage?.file?.url}?fm=webp&q=100) no-repeat`,
    },
  }),
}))
