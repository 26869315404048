import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import LetsTalk from '../LetsTalk'
import './PressReleaseLanding.scss';
import LoadMoreButton from '../LoadMoreButton';
import PressReleaseLandingTiles from "./PressReleaseLandingTiles";
import clsx from 'clsx';
import { myStyles } from './style';

export default function PressReleaseLanding({ landingData, pressData, basePath }) {

  const classes = myStyles(landingData);
  const numberOfPressData = parseInt(process.env.GATSBY_NUMBER_OF_TILE_PRESS_RELEASE);
  const [disableLoadMore, setDisableLoadMore] = useState(false);
  const [colsCount, setColsCount] = useState(numberOfPressData);
  const pressDataList = pressData.map(pressLanding => {
    const months = [
      'JAN',
      'FEB',
      'MAR',
      'APR',
      'MAY',
      'JUN',
      'JUL',
      'AUG',
      'SEP',
      'OCT',
      'NOV',
      'DEC',
    ];
    const dateinTimestamp = new Date(pressLanding?.pressReleaseConent?.dateOfPublication).getTime();
    const month = new Date(Number(dateinTimestamp)).getMonth();
    const monthString = months[month];
    const day = new Date(Number(dateinTimestamp)).getDate();
    const formatedDateString = monthString + ' ' + day + ', ' + new Date(Number(dateinTimestamp)).getFullYear();
    return {
      ...pressLanding,
      publishingDate: formatedDateString,
      dateinTimestamp: dateinTimestamp
    };
  });
  function comparePublishingDate(a, b) {
    return b.dateinTimestamp - a.dateinTimestamp;
  }
  pressDataList.sort(comparePublishingDate);

  const tilesAddMore = () => {
    let nextColsCount = pressData.length > colsCount + numberOfPressData
      ? colsCount + numberOfPressData
      : pressData?.length;
      if (pressDataList.length == nextColsCount) {
        setDisableLoadMore(true);
      }
    setColsCount(nextColsCount);
  };

  return (
    <>
      <Grid className="pressRelease">
        <Grid className={clsx('donute-bg-right', classes['bg-donuts-right'])}
              locId="rightDonutBackgroundImage">
          <Grid className={clsx('capsules-bg-right', classes['bg-capsules-right'], classes['bg-dot-matrix'])}
                locId="capsulesBackgroundImage">
            <Container maxWidth="xl" className='pressRelease-Container'>
              <Grid container>
              <Grid item xl={2} lg={2}></Grid>
                <Grid item xl={8} lg={8} md={12} sm={12} xs={12} >
                  <Grid item xl={10} lg={10} md={9} sm={10} xs={9} locId="header">
                    <h1 className="PressRelease-heading">{landingData?.header}</h1>
                  </Grid>
                  <PressReleaseLandingTiles pressData={pressDataList} basePath={basePath} colsCount={colsCount}/>
                </Grid>
                <Grid item xl={2} lg={2}></Grid>
              </Grid>
              {!disableLoadMore ? (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <LoadMoreButton loadMoreButton={landingData?.loadMoreButton}
                    handleLoadMore={tilesAddMore}
                    tabindex="0" 
                    locId="loadMoreButton" />
                </Grid>
              ) : (
                <></>
              )}
            </Container>
          </Grid>
        </Grid>
      </Grid>
      <LetsTalk
        title={landingData?.letsTalkSectionTitle}
        image={landingData?.letsTalkSectionBackgroundImage}
        LetsTalkButton={landingData?.letsTalkButtonWithLink}
        basePath={basePath}
        LetsTalktitle= "letsTalkSectionTitle"
        LetsTalkBackgroundImage="letsTalkSectionBackgroundImage"
        ButtonLabel="letsTalkButtonLink"
      />
    </>
  )
}